import { autoinject, LogManager } from "aurelia-framework";
import { Logger } from "aurelia-logging";

@autoinject
export class GuiEmails {

  private logger: Logger;

  constructor() {
    this.logger = LogManager.getLogger("GuiEmails");
  }

}
