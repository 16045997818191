/// <reference types="aurelia-loader-webpack/src/webpack-hot-interface"/>
// css
import 'font-awesome/css/font-awesome.css';
// app style
import '../assets/styles/main.scss';

// config
import config from 'config.js';

// js
import { Aurelia } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-pal';
import * as Bluebird from 'bluebird';
// remove out if you don't want a Promise polyfill (remove also from webpack.config.js)
Bluebird.config({ warnings: { wForgottenReturn: false } });

declare const IS_PRODUCTION: boolean; // The value is supplied by Webpack during the build

export async function configure(aurelia: Aurelia) {

  // if (environment.useHttps && location.protocol !== "https:") {
  //   // location.protocol is buggy in Firefox
  //   // see also http://stackoverflow.com/a/10036029
  //   location.href = "https:" + window.location.href.substring(window.location.protocol.length);
  // }
  console.log("IS_PRODUCTION: " + IS_PRODUCTION);

  aurelia.use
    .developmentLogging()
    .standardConfiguration();

  // if (environment.debug) {
  //   aurelia.use.developmentLogging();
  // }

  // if (environment.testing) {
  //   aurelia.use.plugin(PLATFORM.moduleName('aurelia-testing'));
  // }

  // Uncomment the line below to enable animation.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-animator-css'));
  // if the css animator is enabled, add swap-order="after" to all router-view elements

  // Anyone wanting to use HTMLImports to load views, will need to install the following plugin.
  // aurelia.use.plugin(PLATFORM.moduleName('aurelia-html-import-template-loader'));

  return await aurelia.start().then(async () => {
    return await aurelia.setRoot(PLATFORM.moduleName('app'));
  });
}
