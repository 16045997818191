import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router, RouterConfiguration, RouteConfig } from 'aurelia-router';
import { PLATFORM } from 'aurelia-pal';

@autoinject()
export class App {

  private logger: Logger;
  private router: Router;


  constructor(
  ) {

    this.logger = LogManager.getLogger('App');
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {
    this.logger.debug("activate");
  }

  public async configureRouter(routerConfiguration: RouterConfiguration, router: Router): Promise<any> {

    // switch from hash (#) to slash (/) navigation
    routerConfiguration.options.pushState = true;
    routerConfiguration.title = "Tht apps style";

    // configure routes
    routerConfiguration.map([
      {
        route: ['', 'home'],
        moduleId: PLATFORM.moduleName('./home'),
        name: 'home',
        title: 'Home',
        nav: true,
      }, {
        route: 'gui',
        moduleId: PLATFORM.moduleName('./gui'),
        name: 'gui'
      }, {
        route: 'gui-emails',
        moduleId: PLATFORM.moduleName('./gui-emails'),
        name: 'gui-emails'
      }
    ]);

    this.router = router;

  }
}
