import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { RouteConfig } from 'aurelia-router';

@autoinject()
export class Home {

  private logger: Logger;

  constructor() {

    this.logger = LogManager.getLogger('Home');
  }

  private async activate(params: any, routeConfig: RouteConfig): Promise<any> {

  }

}
